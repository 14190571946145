html, body, #root {
  --primary-color: #6CC62B;
  --text-color-secondary: black;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
}

body {
  overflow-y: scroll;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.p-button{
  border-color: var(--text-white);
}

.p-button, .p-inputtext, .p-dropdown {
  border-radius: 7px;
}

.p-error {
  font-weight: 500;
}

/* https://github.com/primefaces/primereact/issues/3498 */
.p-toast {
  position: fixed;
  width: auto;
}

.p-dialog-footer{
  display: flex;
  justify-content: space-evenly;
}

.p-confirm-dialog-accept{
  margin-right: 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link{
  color: var(--text-color-secondary);
  border-color: #DEE2E6;
}

.p-datepicker-trigger.p-button.p-component.p-button-icon-only{
  background-color: var(--primary-color);
}

.p-inputswitch-slider{
  background-color: var(--primary-color);
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.p-badge {
  border-radius: 7px;
  font-size: 0.875rem;
  font-weight: 600;
}

.p-card-body{
  padding: 0rem;
}

.p-card-content {
  padding: 0rem;
}

.no-tap-highlight {
  -webkit-tap-highlight-color: transparent;
}

.p-timeline-event-opposite{
  display: none;
}

.loading {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  margin-top: 160px;
  background: 
    radial-gradient(farthest-side, var(--primary-color) 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,var(--primary-color));
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation: l13 1s infinite linear;
}

@keyframes l13{ 
  100%{transform: rotate(1turn)}
}


.reader.fullscreen {
  position: fixed !important;
  display: flex !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 4;
  background-color: rgba(0, 0, 0, 1);
}

.reader.fullscreen video {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

#reader__scan_region {
  width: 100% !important;
  height: 100% !important;
}

#reader__scan_region video {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.text-black{
  color: black;
}

/*
.reader.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: black;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}*/

@keyframes blinker {
  50% { opacity: 0; }
}


@media (max-width: 768px) { 
    .mobile-max-width {
        max-width: 500px;
    }
    .mobile-field-list{
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    .mobile-notifications-list{
      flex-direction: column;
      justify-content: center;
      width: 91.6667%;
    }

}

@media (min-width: 768px) {
  .desktop-max-width {
      max-width: 500px;
  }
  
  .desktop-field-list{
    flex-wrap: wrap ;
    align-content: flex-start;
    align-items: center;
    justify-content: space-evenly;
  }

  .desktop-notifications-list{
    flex-wrap: wrap ;
    justify-content: space-evenly;
  }
}